//
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: url("../images/big/bg2.jpg");
    background-size: cover;
    background-position: center;
}

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// Logout page
.logout-icon {
    width: 140px;
}

body.auth-none {
	background-color : #e3e3e3;
	background-size : cover;
	overflow : hidden;
}

.card-login {
  background-color: #fff;
}


body.trial {
  background-color : #fff;
  padding: 40px;
  padding-top: 10px;
  display: "flex";
}
